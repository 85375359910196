$ =>
  $email = $ "#email"
  $email.focus()

  window.reset_password = =>
    $("#forgot-password-modal").modal "show"
    $("#forgot-email").focus()

  if location.hash == "#disclaimer"
    $("#disclaimer-modal").modal "show"
